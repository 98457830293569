@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap');

@import '@cokitchen/cokitchen-components/dist/main.css';
@import '@cokitchen/cokitchen-auth/dist/main.css';
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';
body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-scroll-bar {
  overflow: scroll;
  white-space: nowrap;
}

.no-scroll-bar::-webkit-scrollbar {
  display: none;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Toastify__toast {
  background: none !important;
  box-shadow: none !important;
}

.Toastify__toast-container {
  width: inherit !important;
  z-index: 100000 !important;

  @media (max-width: 500px) {
    max-width: 300px !important;
  }
}
.slick-slide:not(:first-child) {
  margin-left: 15px;
}

.slick-slide:not(:last-child) {
  margin-right: 15px;
}

.slick-track {
  margin-left: 0;
}

.slick-disabled {
  display: none !important;
}

.slick-next {
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  padding: 10px;
  width: 40px;
  height: 40px;
}

.slick-prev {
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  padding: 10px;
  width: 40px;
  height: 40px;
  z-index: 1;
}

.slick-next:hover,
.slick-prev:hover {
  background: rgba(255, 255, 255, 0.9);
}

/* recipt */
@media screen {
  .receipt {
    display: none;
  }
}
