.transaction-tag {
  @apply w-[70px] capitalize flex items-center justify-center py-2 px-3 gap-1 rounded-[20px] text-center text-xs;

  .dot {
    @apply w-[7px] h-[7px] rounded-full;
  }
  &.credit {
    @apply bg-green-1 text-green-5;
    .dot {
      @apply bg-green-5;
    }
  }

  &.debit {
    @apply bg-red-9 text-red-5;

    .dot {
      @apply bg-red-5;
    }
  }
}
