.action-tag {
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  max-width: fit-content;
  font-weight: 500;
  font-size: 10px;
  height: 22px;

  svg {
    width: 10px;
  }
  &.block-tag {
    border-radius: 40px;
    padding: 0 0.9rem;

    &.add {
      color: #0ea3fd;
      background: #e1f3ff;
    }

    &.remove {
      color: #e60a2b;
      background: #fef0f2;
    }
  }

  &.outline-tag {
    background: transparent;
    border-radius: 20px;
    padding: 0 0.5rem;

    &.add {
      color: #0ea3fd;
      border: 1px solid #b3e2fe;
    }

    &.remove {
      color: #f73b58;
      border: 1px solid #fcb6c0;
    }
  }
}
