.edit-icon {
  @apply rounded-[2px] p-[4px] w-6 h-6 flex items-center justify-center cursor-pointer transition-all duration-300 whitespace-normal;

  &.red {
    @apply text-red-5 bg-red-1 hover:bg-red-2;
    &.is-active {
      @apply bg-red-5 text-white;
    }
  }
  &.blue {
    @apply text-blue-5 bg-blue-1 hover:bg-blue-2;
    &.is-active {
      @apply bg-blue-5 text-white;
    }
  }
}

.basket-btn {
  font-size: 12px !important;
  height: 24px;
  padding: 1rem;

  &.small {
    width: 100%;
  }
}
