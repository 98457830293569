.wrapper {
  height: auto;
  overflow: scroll;

  &.tranx {
    height: 400px;
  }
  .title {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 100;
  }
}
