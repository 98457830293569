.search {
  &-input input {
    height: 50px;
    border-radius: 20px;
  }
  &-filter {
    display: flex;
  }
  &-btn {
    height: 50px;
    border-radius: 20px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 120px;
    z-index: 3;
    position: absolute;
    right: 0px;
    top: 8px;
    margin-top: 0;
    @media (max-width: 500px) {
      width: 120px;
    }
  }
}
