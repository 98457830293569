.user-rating {
  div {
    display: flex;
    gap: 0.1rem;
    align-items: center;
  }
  svg {
    width: 14px;
    height: 14px;
    color: #f9c320;
  }
  &.big {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
