section-header,
.section-header-2 {
  border-bottom: 1px solid #e9ebec;
}

.section-header > div {
  border-left: 4px solid #73d260;
}

@layer component {
  .delivery-details-body > div {
    @apply py-1;
  }
  .delivery-details-body > div > span {
    @apply text-sm text-blue-8;
    color: #0ea3fd;
  }
}

.section {
  width: 100%;
  border: 1px solid #e9ebec;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.full-width-button {
  width: 100%;
  border: 1px solid #e9ebec;
  border-radius: 4px;
  padding: 5px;
}

.section-header-2 {
  display: flex;
  justify-content: space-between;
}

.delivery-details-body > div {
  display: flex;
  color: #858585;
  align-items: center;
}

.delivery-details-body > div > svg {
  margin-right: 0.75rem;
}
.delivery-details-body > div > div {
  flex: auto;
}

.delivery-note {
  background: #f5f5f5;
}

.faint-background-button {
  background: #f5f5f5;
  /* Content Color/Black 800 */

  border: 1px solid #e9ebec;
  border-radius: 4px;
  padding: 5px;
}

.track-mark {
  display: flex;
  flex-direction: column;
}

.track-mark > div {
  flex: auto;
  border: 1px solid #e9ebec;
  margin: auto;
  width: 1px;
  margin-bottom: -3px;
  min-height: 30px;
}

.track-mark > svg {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  color: #e9ebec;
}

.track-mark.active > svg {
  color: #a8e39c;
}
.track-mark.active > div {
  border-color: #73d260;
}

.track-states-container {
  margin-top: -1rem;
  margin-bottom: -1rem;
}
