.right-section {
  box-shadow: -2px 1px 3px #6666660d, 2px 1px 3px rgba(102, 102, 102, 0.05);
  border: 1px solid #e2e7ee;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-top: -40px;
  position: sticky;
  top: 40px;
  height: 100vh;

  .no-wrap {
    white-space: normal !important;
  }

  .active {
    @apply border-l-2 bg-[#F7F7F8] border-l-red-4 -mx-4 px-4;
  }
  .inactive {
    @apply flex gap-4 items-center transition-all py-3 duration-75 cursor-pointer;
  }

  .icon {
    @apply cursor-pointer w-4 h-4 p-[1px] flex justify-center items-center rounded-[100%] bg-gray-11;
  }
}
